&.info {
  color: @color-light-1;
}

.status-message {
  border-radius: 5px;
  padding: 1rem;
  font-weight: bold;
  &.error {
    background: rgba(
      red(@color-status-alert),
      green(@color-status-alert),
      blue(@color-status-alert),
      0.5
    );
    border: 1px solid @color-status-alert;
  }
  &.success {
    background: rgba(
      red(@color-status-good),
      green(@color-status-good),
      blue(@color-status-good),
      0.5
    );
    border: 1px solid @color-status-good;
  }
  &.warning {
    background: rgba(
      red(@color-status-warning),
      green(@color-status-warning),
      blue(@color-status-warning),
      0.5
    );
    border: 1px solid @color-status-warning;
  }

  &.action {
    background: rgba(
      red(@color-action-main),
      green(@color-action-main),
      blue(@color-action-main),
      0.5
    );
    border: 1px solid @color-action-main;
  }  
}

.status-inline {
  display: flex;
  font-weight: bold;

  &.error {
    color: @color-status-alert;
  }
  &.success {
    color: @color-status-good;
  }
  &.warning {
    color: @color-status-warning;
  }

  &.action {
    color: @color-action-main;
  }  
}