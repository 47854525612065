@app-gutters: 50px;


.app-main {
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 8.1vw;
}

.app-inner {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 1024px;
}

.primary-section {
  padding: 2rem 0;
}

section.inset {
  // background-color: @color-dark-2;
  // background-image: url('../img/inset-bg.png');
  // background-repeat: repeat;
  // background-size: 25px 25px;
  border-radius: 12px;
  box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  border: 1px solid @color-dark-2;
  & > .inner {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    a {
      color: inherit;
    }
  }

  &.highlight {
    background: @color-bg-highlight;
  }
}

section.inner {
  padding: 2rem 2rem 0 2rem;
}

.basic-page {
  padding: 2rem 0;
  min-height: 100vh;
}
