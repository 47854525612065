.explorer-main {
  padding: 25px 0;
  display: flex;
  .all-tab-column-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .all-tab-column-header-label {
      padding: 0.5rem 0rem;
    }
  }
  & > .inset {
    height: 50vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border: 2px solid @color-border-0;
    border-top-left-radius: 0;
    & > .inner {
      position: relative;
      display: flex;
      flex: 1;
      border-radius: 0px;
    }
  }
  // flexbox hackery to make sure sure explorer table parent is as tall as possible
  .explorer-main-content {
    display: flex;
    flex-direction: column;
    .tabs-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      .tab-inner {
        flex: 1;
        display: flex;
        flex-direction: column;
        .explorer-locations {
          flex: 1;
        }
      }
    }
  }

  .tab-layout {
    flex: 1;
    .tabs-menu {
      margin: 0;
      position: absolute;
      top: -55px;
      left: -3.9%;
      width: 50%;
      .tab {
        width: 162px;
        border-radius: 25px 25px 0px 0px;
        border-left: 0.5px solid #c7c8ca;
        font-size: 16px;
        h3 {
          justify-content: center;
        }
      }
      .tab:first-child {
        border-left: 2px solid #c7c8ca;
      }
    }
  }

  .explorer-next-loading {
    position: absolute;
    top: 100%;
    width: 100%;
    text-align: center;
    margin: 2.3rem 0 0;
  }

  .explorer-locations {
    position: relative;
    .explorer-table-loading {
      position: absolute;
      left: 0;
      bottom: 100%;
      padding: 0.5rem 0;
    }
    .date-range {
      display: flex;
      width: 300px;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;
      position: absolute;
      right: 0;
      bottom: 100%;
      .label {
        font-weight: bold;
      }
      a {
        display: block;
        padding: 0.2rem;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: transparent;
        text-decoration: none;
        &:not([disabled]) {
          cursor: pointer;
          color: @color-light-1;
          text-decoration: none;
          &:hover {
            border-bottom-color: initial;
          }
          &.active {
            color: @color-action-secondary;
            border-bottom-color: @color-action-secondary;
          }
        }
      }
    }
  }

  .location-installations {
    .connectivity-indicator {
      .label {
        display: none;
      }
    }
  }
}

/* google maps, hide GM's native popups*/
.gm-style .gm-style-iw, .gm-style .gm-style-iw-tc{ 
  display: none;
}

.hublist {
  padding: 0.3rem;
  cursor: pointer;
  // column-count: 4;
  list-style: none;

}
.hublist:hover {
  color: @color-light-1;
}
.hubListManager{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}