@import (less) "../../../shared/styles/forms/index.less";
@import "radio.less";

.curb-input input,
.curb-select select {
  color: @color-text-default;
  background: @color-light-5;
  border: 0;
  border-radius: 5px;
  .placeholder({
    color: @color-light-0;
    opacity: 0.5;
  });
}

.curb-select select {
  height: 1.95rem;
  line-height: 1.95rem;
  padding-right: 1rem;
}

.curb-form {
  position: relative;
  padding-bottom: 1rem;

  &.busy:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.5;
    background: @color-light-5;
  }

  .curb-form-element {
    label {
      padding-bottom: 0.2rem;
    }
  }

  .curb-form-element + .curb-form-element {
    margin: 1rem 0 0;
  }

  .form-row {
    display: flex;
    .curb-form-element {
      margin-top: 0;
      & + .curb-form-element {
        margin-left: 1rem;
      }
    }
  }

  .search-input-wrap {
    position: relative;
    .curb-input {
      z-index: 1;
      border-bottom: 1px solid @color-text-default-light;
      & > input[type] {
        padding-left: 2rem;
        padding-right: 2rem;
        box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
    .icon {
      position: absolute;
      font-size: 1.1rem;
      top: 50%;
      transform: translateY(-0.55rem);
    }

    .search-icon {
      right: 0;
      color: @color-text-default-light;
      width: 14px;
    }
    .search-clear-icon {
      transition: color 0.1s ease-in-out;
      right: 20px;
      padding: 0.25rem;
      height: 1.2rem;
      font-size: 1.6rem;
      transform: translateY(-1rem);
      cursor: pointer;
      z-index: 2;
      &:hover {
        color: @color-light-1;
      }
    }
  }

  .search-busy-indicator {
    position: absolute;
    top: 0;
    left: 100%;
    padding: 0.25rem;
  }

  .curb-form-element {
    &.curb-input,
    &.curb-select {
      margin-bottom: 1.5rem !important;
      // &:after {
      //   content: '';
      //   display: block;
      //   padding-bottom: 1rem;
      // }
    }
  }

  .value {
    font-weight: bold;
    padding: 0.5rem;
  }
}

.curb-submit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 25px;
  button {
    margin-left: 2rem;
  }
  .success {
    color: @color-status-good;
    font-size: 1.4rem;
  }
  .status-message {
    background: none;
    color: inherit;
    border: none;
    &.error {
      color: @color-status-alert;
    }
    &.success {
      color: @color-status-good;
    }
  }
}

.modal {
  .curb-input {
    input {
      width: 100%;
    }
  }
}

@import "add-user.less";
@import "add-hub.less";
