.add-user-form.curb-form {
  display: flex;
  padding-bottom: 1rem;
  .curb-form-element {
    margin-top: 0;

    input[name="email"] {
      width: 15rem;
      &::placeholder {
        color: @color-text-default;
      }
    }

    + .curb-form-element {
      margin-left: 1rem;
    }
  }
}
