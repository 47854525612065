.fleet-link {
  color: @color-action-secondary;
  &:hover {
    text-decoration: underline;
  }  
}


.fleet-nav-list a.active {
  font-weight: bold;
}