.app-header {
  display: flex;
  justify-content: space-between;
  padding: 2.2rem 4.44rem .9rem;
  .curb-logo-container {
    transform: scale(101%);
    h2 {
      font-weight: 400;
      transform: translateY(3px);
      font-size: 1.33rem;
    }
    margin: 0;
    display: flex;
    align-items: center;
    font-size: revert;

    .curb-logo {
      width: 110px;
      margin-right: 0.5rem;
    }
  }
}

.primary-nav {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0.5rem;
}

.fleet-nav {
  flex-grow: 2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .title {
    margin-right: 0.5rem;
  }

  .inner {
    position: relative;
    display: inline-block;
    display: flex;
    align-items: center;
    align-self: stretch;
  }

  .fleet-no-logo {
    z-index: 1;
    font-size: 2rem;
    color: @color-light-3;
  }

  .fleet-img {
    z-index: 1;
    max-width: 100%;
  }

  .fleet-id {
    position: absolute;
    bottom: -1.5rem;
    right: -1.5rem;
    color: @color-light-3;
  }

  .menu-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      content: "Change fleet";
      text-decoration: underline;
      padding: 0.5rem;
      border-radius: 5px;
      background: rgba(
        red(@color-action-main),
        green(@color-action-main),
        blue(@color-action-main),
        0.9
      );
      transform: translate(0, -0.5rem);
      // opacity: 0;
      transition: all 0.1s ease-in-out;
    }

    // &:hover {
    //   &:after {
    //     opacity: 1;
    //     transform: translate(0, 0);
    //   }
    // }
  }

  .fleet-menu {
    cursor: pointer;
    z-index: 2;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
  }

  .caret {
    height: 1rem;
    margin-left: 0.4rem;
  }
}

.user-nav {
  display: flex;
  align-items: center;
  .user-menu-link-main {
    transform: translate(7px, 0px);
  }
  .arrow-down {
    margin: 0rem 0.5rem;
    cursor: pointer;
    width: 14px;
  }
  .verticle-pipe {
    font-size: 1rem;
    margin-right: 0.5rem;
    transform: translateY(2px);
    display: inline-block;
  }
  .header-fleet-name {
    font-weight: bold;
    margin-right: 0.5rem;
    text-transform: uppercase;
  }

  .user-menu-link {
    text-decoration: underline;
    text-underline-position: below;
    color: @color-text-default;
    margin-right: 1rem;
  }

  .user-actions {
    padding: 0.75rem 0;
  }
  .pass-reset-container {
    display: flex;
    justify-content: center;
    height: 3rem;
    width: 250px;
    border: 1px solid #eee;
    border-right: none;
    border-radius: 35px;
    box-shadow: 0 0 12px 6px #00000021;
    z-index: 0;
    .pass-reset-main {
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .logout {
      margin-right: 0rem;
      font-weight: 600;
      cursor: pointer;
      margin-left: 0.2rem;
    }
    .pass-reset {
      cursor: pointer;
      margin-right: 0.2rem;
    }
    .verticle-pipe {
      transform: translate(3px, 0px);
    }
  }
}

.tooltip.user-menu {
  padding: 1rem;
  .tooltip-content > .menu > *:first-child {
    margin-top: 0;
  }
  .links {
    margin: 0 -1rem;
    a {
      padding: 0.5rem 1rem;
      display: block;
    }
  }
}

.fleet-nav-modal {
  .fleet-nav-list {
    padding-left: 0;
    list-style-position: inside;
    ul.top-level {
      padding-left: 0;
      ul {
        padding-left: 0.5rem;
      }
    }
    li {
      padding: 0.5rem;
      &.active {
        background: @color-light-5;
        border: 1px solid @color-action-main;
        border-radius: 0.5rem;
        font-weight: bold;
        // background: rgba(red(), green(), blue(), 0.5);
      }
    }
    a {
      cursor: pointer;
      padding: 0.5rem 0;
      &:hover {
        text-decoration: underline;
      }
    }
    ul {
      padding-left: 1rem;
    }
  }
}

.fleet-nav-list { 
  li.active > a {
      font-weight: bold;
    
  }
}