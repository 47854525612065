// @color-status-alert: #ff6c64;
// @color-status-warning: #ffb152;
// @color-status-good: #69df73;

.connectivity-indicator {
  display: inline-block;
  margin-bottom: 0.5rem;
  span {
    margin-left: 0.25em;
    &:first-child {
      margin-left: 0;
    }
  }

  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 0.5rem;
    background: @color-light-4;
    box-shadow: 0px 0px 10px 0px @color-light-4;
  }

  &.none:before {
    background: @color-status-alert;
    box-shadow: 0px 0px 10px 0px @color-status-alert;
  }
  &.weak:before {
    background: @color-status-warning;
    box-shadow: 0px 0px 10px 0px @color-status-warning;
  }
  &.strong:before {
    background: @color-status-good;
    box-shadow: 0px 0px 10px 0px @color-status-warning;
  }
}
