@import 'data-download.less';
@import 'energy-projections.less';
@import 'add-user-modal.less';
@import 'change-password-modal.less';
@import 'eula.less';

.modal {
  .section-title {
    h3 {
      font-size: 0.9rem;
    }  
  }

  &.overflow-visible > .modal-wrapper {
    overflow: visible
  }
}

.generic-modal-wrapper {
  position: fixed;
  top: 0; right: 0; left: 0; bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.generic-modal {
  margin: auto;
  max-width: 600px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin-top: 230px;
  position: relative;
}
.generic-modal-close {
  position: absolute;
  top: -20px;
  right: 10px;
  height: 35px;
  width: 35px !important;
  min-width: 35px !important;
  border-radius: 50px;
}

.generic-modal-close:hover {
  background-color: @color-dark-2;
  opacity: 1;
  cursor: pointer;
}

.generic-modal .red {
  color:@color-status-alert;
}

.generic-modal .green {
  color:@color-status-good;
}

.generic-modal .gray {
  color:@color-light-4;
}

.add-location-status {
  text-align: right;
}