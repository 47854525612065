.location-row-label {
  padding-left: 1rem;
  position: relative;
  .label-main {
    overflow-y: hidden;
    overflow-x: auto;
  }
  .location-expand-toggle {
    padding: 0.05rem 0.2rem;
    position: absolute;
    left: -6px;
    top: 0;
    cursor: pointer;
    img {
      width: 11px;
    }
    // .caret {
    //   width: 0;
    //   height: 0;
    //   border-top: 0.38rem solid transparent;
    //   border-left: 0.68rem solid @color-light-2;
    //   border-bottom: 0.38rem solid transparent;
    //   transition: all 0.1s ease-in-out;
    //   transform-origin: 40% center;
    // }
    .arrow-expand {
      transform: rotate(0deg);
    }
    .arrow-collapse {
      transform: rotate(272deg);
    }
    // &:hover {
    //   .caret {
    //     border-left-color: @color-light-0;
    //   }
    // }

    // &.expanded {
    //   .caret {
    //     transform: rotate(90deg);
    //   }
    // }
  }
  .location-label {
    display: flex;
    width: 100%;
    overflow: hidden;
    justify-content: flex-start;
    .label-main {
      padding: 0 0.5rem;
      // font-weight: bold;
      // color: @color-light-0;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .location-edit-link {
      display: flex;
      flex-direction: row-reverse;
      margin-right: 5%;
      .loading-spinner,
      .edit {
        margin-top: 0;
      }
      .location-edit {
        color: @color-text-default;
        padding: 0;
        font-size: 0.9rem;
        margin-left: 0.2rem;
      }
    }
  }
  .row-expanded-section {
    margin-left: 0.75rem;
  }
  .row-left-align {
    text-align: initial;
    margin-top: 0.3rem;
  }
  .row-expand-icon-section {
    display: flex;
    height: 10vh;
    transform: translateX(-3%);
    .row-expand-icons {
      cursor: pointer;
      // box-shadow: #a5a0a0 0px 0px 4px;
      // padding: 0.2rem;
      img {
        width: 45px;
      }
    }
    a {
      position: relative;
      .loading-spinner {
        position: absolute;
        top: -16%;
        left: 38%;
      }
    }
  }
  .address-full {
    // color: @color-light-1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .button-list {
    margin: 1rem 0;
  }
}

.expanded .location-row-label {
  .label {
    white-space: normal;
  }
  .location-label .label-main {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
}

.appliance-inventory-modal-container {
  .modal-wrapper {
    width: 75%;
    .close {
      background-color: white;
      color: @color-text-default;
      svg {
        width: 1rem;
        height: 3rem;
      }
    }
    .inset {
      border: 0px;
      .inner {
        box-shadow: none;
      }
    }
  }
  .appliance-inventory-modal-content {
    img {
      width: 100%;
    }
  }
}
