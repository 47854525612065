button,
.button {
  background: @color-text-default;
  overflow: hidden;
  color: @color-light-0 !important;
  display: inline-block;
  position: relative;
  padding: 0.5rem;
  min-width: 6rem;
  text-align: center;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  outline: 0;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not([disabled]):hover {
    opacity: 0.8;
  }

  &.irreversible {
    background: @color-action-irreversible;
  }

  &.passive {
    background: @color-light-3;
  }

  & > .loading-spinner.full {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: inherit;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.small {
    padding: 0.2rem 0.4rem;
    min-width: 5rem;
    font-size: 13px;
  }
}

.button-list {
  display: flex;
  .download-data-button {
    font-size: 12px;
    padding: 0.4rem;
  }
  &.center {
    justify-content: center;
  }
  & > * + * {
    margin-left: 1rem;
  }

  &.vertical {
    display: block;
    & > * + * {
      margin-left: 0rem;
      margin-top: 1rem;
    }
  }
}
