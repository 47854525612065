.energy-projections-search-form {
  label {
    display: block;
    padding-bottom: 0.25rem;
    font-style: italic;
  }
  .search-input-wrap {
    input[type] {
      display: block;
      width: 100%;
    }
  }
  .search-busy-indicator {
    top: auto;
    bottom: 1rem;
  }
}

.installations-list {
  font-style: italic;
  padding: 1rem;
  .installation-serials {
    font-family: monospace;
    text-transform: uppercase;
    font-weight: bold;
    li {
      list-style: none;
    }
  }
  .installation-error {
    display: inline-block;
  }
}

.energy-projections-associated-form {
  label {
    margin-bottom: 0.2rem;
  }
  input,
  select {
    width: 100%;
    border-radius: 2px;
    &[disabled] {
      opacity: 0.5;
    }
  }
  .address-row-2 {
    display: flex;
    .curb-form-element {
      margin-top: 0;
      width: 50%;
      &:first-child {
        margin-right: 1rem;
      }
      & + .curb-form-element {
        margin-left: 1rem;
      }
    }
  }
}