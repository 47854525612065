.eula-modal {
  .modal-wrapper {
    overflow-y: auto;
    // width: 1024px;

    .loading-spinner {
      display: flex;
      justify-content: center;
    }

    .eula-content {
      a {
        color: @color-light-1;
        text-decoration: underline;
        text-underline-position: under;
        &:hover {
          color: @color-action-secondary;
        }
      }

      &.eula-content-links {
        line-height: 1.6;
        font-size: 1rem;
        text-align: center;
      }

    }
  }
}