.curb-form {
  .curb-form-element {
    label {
      display: block;
    }
    input {
      margin: 0;
      &:not([type=radio]):not([type=checkbox]) {
        padding: 0.5rem;
      }

      &[type=number]::-webkit-inner-spin-button, 
      &[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
      }      
    }

    &.error {
      label {
        color: inherit;
      }
      .error-msg {
        color: red;
        font-weight: bold;
        position: absolute;
        transform: translateY(5px);
      }
    }

    input:focus {
      outline: 0;
    }

  }
  .curb-select {
    select {
      margin: 0;
      // for windows
      option {
        color: #000;
      }
    }
  }
}
