.icon-link {
  display: flex;
  color: @color-action-secondary;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    .round-icon {
      color: @color-light-0;
      background: @color-action-main;
    }
  }
}

.linked-icon {
  padding: 0.25rem;
  margin-top: -0.5rem;
  transform: translateY(0.1rem);
  cursor: pointer;
  // &:hover {
  //   color: @color-light-0;
  //   background-color: @color-text-default;
  // }
}

.round-icon {
  width: 16px;
  height: 16px;
  padding: 0;
  // margin-right: 0.5rem;
  display: flex;
  flex-shrink: 0;
  border-radius: 50%;
  // border-width: 1px;
  // border-style: solid;
  color: @color-action-secondary;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  transition: all 0.1s ease-in-out;

  svg {
    width: 12px;
    height: 12px;
  }

  &:hover {
    color: @color-light-0;
    background: @color-action-secondary;
  }

  &.irreversible {
    color: @color-status-alert;
    &:hover {
      color: @color-light-0;
      background: @color-status-alert;
    }
  }
}
