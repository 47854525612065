.energy-projections-modal {
  .modal-wrapper {
    // min-width: 1000px;
  }
  .energy-projections-tabbed-content {
    .tabs-menu {
      margin: -3rem 2rem 2rem;
      h3 {
        font-size: 0.9rem;
      }
    }

    .energy-projections-values-form {
      
      &[disabled] {
        opacity: 0.7;
        &:after {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }

      .section-title {
        margin: 0;
        display: block;
        text-align: left;
        text-transform: uppercase;
        h3 {
          padding-left: 0;
          padding-right: 0;
          background: none;
          box-shadow: none;
          margin-top: inherit;
          margin-bottom: 1rem;
          font-weight: normal;
        }
      }
      .highlight {
        background: inherit;
        box-shadow: none;
      }
      .inner {
        padding: 0;
        box-shadow: none;
      }

    }
  }
}