@import 'search.less';
@import 'values/index.less';

.energy-projections {
  
  .energy-projections-location-summary {
    display: grid;
    column-gap: 2rem;
    grid-template-columns: 2fr 3fr 3fr;

    .inner {
      flex: 1;
    }
  }

}

