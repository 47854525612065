.add-hub-compare-locations {
  display: flex;
  margin-bottom: 1rem;
  .location-item {
    flex: 1;
  }
}

.modal-manager > .add-user {
  margin-top: 0.3rem; 
}