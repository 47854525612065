.explorer-search {
  display: flex;
  flex: 1;
  form {
    width: 17rem;
    position: relative;
    .curb-input.search > input {
      display: block;
      width: 100%;
      padding-left: 1.8rem;
      padding-right: 1.5rem;
    }
    .search-busy-indicator {
      position: absolute;
      top: 0;
      left: 100%;
      padding: 0.25rem;
    }
    .info {
      padding-left: 1rem;
    }
  }

  .explorer-totals-table-wrapper {
    flex: 1;
    padding-left: 2rem;
    // min-height: 10rem;
    .ReactVirtualized__Table {
      .ReactVirtualized__Table__rowColumn:not(:first-child) {
        align-self: center;
        justify-self: center;
        text-align: center;
        align-content: center;
        padding-right: 10px; // accounts for draggable
      }
      .totals-label {
        color: @color-light-0;
      }
    }
  }
}
