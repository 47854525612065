#tooltip-wrapper {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
}

.tooltip-trigger {
  cursor: pointer;
}

@import '../../../shared/styles/atoms/tooltip.less';

.tooltip {
  .tooltip-content {
    padding: 0.5rem 0;
  }
  background: @color-row-selected;
  .tooltip-arrows(@color-row-selected);

  &.error {
    .tooltip-content {
      min-width: 200px;
      max-width: 600px;
    }
    background: @color-status-alert;
    .tooltip-arrows(@color-status-alert);
    @tooltip-error-border-color: rgba(255,255,255, 0.5);
    &.border {
      border: 1px solid @tooltip-error-border-color;
      .tooltip-arrow {
        &:before,
        &:after {
          border-bottom: 1px solid @tooltip-error-border-color;
        }
      }
    }
  }
}