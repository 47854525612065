@import "table/index.less";
@import "search.less";
@import "main.less";
@import "historical-totals.less";

.app-main > .explorer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  .explorer-main {
    flex-grow: 1;
  }
}

@explorer-gutter: 2rem;

.explorer-header {
  display: flex;
  width: 100%;
  min-height: 11rem;
  // grid-template-columns: 2fr 1fr;

  .section-search {
    margin-right: @explorer-gutter / 2;
    flex: 1;
    & > .inner {
      display: flex;
      width: 100%;
    }
  }
  .section-fleet {
    margin-left: @explorer-gutter / 2;
    max-width: 25%;
    background: @color-light-0;
    flex-direction: row;
    & > .inner {
      display: flex;
    }
    // img {
    //   max-height: 20px;
    // }
  }
}

.time-and-search-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  transform: translateY(45px);
  .time-select-container {
    width: 11%;
    margin-right: 1.3rem;
  }
  .search-container {
    width: 23%;
    input {
      color: @color-text-default;
      background: @color-light-0;
      border: 0;
      border-radius: 5px;
      width: 100%;
      .placeholder({
    color: @color-text-default;
    font-size: 14px;
    opacity: 1;
  });
      padding-left: 0rem !important;
    }
  }
}

.fleet-selection-and-map-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 41vh;
  margin-top: 2rem;
  .fleet-selection-and-tab-summary-section {
    width: 49.2%;
    border: 2px solid @color-border-0;
    border-radius: 17px;
    .fleet-selection-and-tab-summary-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .fleet-selection-container {
        ::-webkit-scrollbar {
          width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: @color-light-5;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: @color-text-default;
        }
        padding: 2rem;
        p {
          z-index: 1;
          color: @color-light-6;
          font-size: 0.8rem;
          transform: translateY(-21px);
          font-weight: 500;
        }
        .dropdown-container {
          position: relative;
          cursor: pointer;
          .dropdown-content {
            z-index: 2;
            position: absolute;
            top: 60%;
            width: 100%;
            height: 40vh;
            background-color: @color-light-0;
            overflow-y: auto;
            box-shadow: 0px 26px 106.8px 13.2px rgba(0, 0, 0, 0.38);
            ul.top-level {
              padding-left: 0;
              ul {
                padding-left: 0.5rem;
              }
            }
            li {
              list-style: none;
              // &.parent {
              //   // & > a {
              //   //   // font-weight: bold;
              //   // }
              // }
              a {
                padding: 0.65rem;
                display: block;
              }
              &.active > a,
              & > a:hover {
                background: @color-light-5;
              }
            }
          }
        }
      }
      .tab-summary-container {
        display: flex;
        justify-content: space-between;
        border-top: 2px solid @color-border-0;
        .tab-data-container {
          display: flex;
          flex: 1;
          justify-content: center;
          color: @color-light-6;
          font-size: 18px;
          padding: 1.7vw 0.4vw;
          font-weight: 400;
          .data-number {
            font-weight: 500;
            color: @color-text-default;
          }
        }
        .border-right {
          border-right: 2px solid @color-border-0;
        }
        .border-left {
          border-left: 2px solid @color-border-0;
        }
      }
    }
  }
  .map-container {
    border-radius: 17px;
    overflow: hidden;
    width: 49.2%;
    .container {
      width: 100%;
      height: 41vh;
      .cluster-marker {
        width: 2rem;
        height: 2rem;
        background-color: @color-light-0;
        border-radius: 50%;
        border: 1px solid @color-dark-0;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .locationMarker {
        z-index: 9999;
        width: 34px;
        position:absolute;
        top: -30px;
      }
      .googleMapsWordBubble {
        min-width: 150px;
        max-width: 150px;
        border-radius: 5px;
        background-color: #ffffff;
        border: 1px solid #4f75d0;
        color: #4f75d0;
        position: relative;
        top: -91px;
        left: -75px; 
        z-index: 10000;
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.38);
      }
      .clickToClose {
        cursor: pointer;
        position: absolute;
        right: 5px;
      }

      .googleMapsWordBubble p {
        white-space: nowrap;
        font-size: x-small;
        overflow:hidden;
        text-overflow: ellipsis;
        width: 140px;
        margin: 2px auto;
        padding: 5px 5px 0px 5px;
      }
      div {
        // border-radius: 17px;
      }
      button {
        min-width: initial;
      }
    }
  }
}

.no-list li{
  list-style-type: none;
}

.no-border {
  border: none;
}