#modal-wrapper {
  position: relative;
  z-index: 999;
}

.modal {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.flex-width > .modal-wrapper {
    width: auto;
  }
}

.modal-wrapper {
  position: relative;
  width: 685px;
  max-width: 90%;
  max-height: 90%;
  box-shadow: 0 0 8px rgba(0,0,0,0.5);
  overflow: hidden;
  & > .close {
    position: absolute;
    cursor: pointer;
    padding: 0.25rem;
    top: 0;
    right: 0;
    opacity: 0.5;
    transition: opacity 0.1s ease-in-out;
    &:hover {
      opacity: 1;
    }
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}

.modal-content {
  padding: 2rem;
  background: #fff;
}

.modal-footer {
  padding: 2rem;
  margin: 0 -2rem -2rem;
}

.modal-buttons {
  display: flex;
  margin: 2rem 0 0;
  justify-content: flex-end;
  &.center {
    justify-content: center;    
  }
  &.left {
    justify-content: flex-start;
  }
}