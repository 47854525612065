.error-with-tooltip {
  display: inline-block;
  margin-right: 0.5rem;
  color: @color-light-0;
  .status-inline svg {
    width: 16px;
    height: 16px;
    margin-right: 0 !important;
  }
}

button,
.button {
  .error-with-tooltip {
    margin-left: 0.5rem;
    margin-right: 0;
    vertical-align: middle;    
  }
}
