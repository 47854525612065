.tab-layout {
  .tabs-menu {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    & > .tab {
      padding: 1rem;
      border: 1px solid @color-blue;
      color: @color-blue;
      cursor: pointer;
      & + .tab {
        border-left: none;
      }
      &:first-child {
        border-radius: .5rem 0 0 .5rem;
      }
      &:last-child {
        border-radius: 0 .5rem .5rem 0;
      }

      &.tab-active {
        background: @color-blue;
        color: @color-white;
      }

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
      }

    }
  }
}
