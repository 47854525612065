@import "../../../shared/styles/atoms/tab-layout.less";

.tab-layout {
  .tabs-menu {
    justify-content: flex-start;
    transform: scale(85%);
    .tab {
      border: 2px solid @color-border-0;
      color: @color-text-default-light;
      height: 60px;
      display: flex;
      justify-content: center;
      text-transform: uppercase;
      border-bottom: 1px solid transparent;
      font-weight: bold;
      align-items: center;
      &.tab-active {
        color: @color-text-default;
        background-color: @color-light-0;
      }
    }

    h3 {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      margin: 0;
      font-size: 1rem;
      .status {
        display: inline-block;
        text-align: center;
        height: 1rem;
        line-height: 1rem;
        margin-left: 0.4rem;
        font-weight: 400;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
