.loading-spinner {
  &.tiny {
    margin-top: -3px;
    margin-bottom: -3px;
    &:after {
      width: 16px;
      height: 16px;
      border-width: 3.5px;  
    }
  }
}

.application-loader {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1.1rem;
    font-weight: bold;
  }
}