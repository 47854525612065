@import '../../../shared/styles/atoms/modal.less';

.modal {
  .modal-wrapper {
    background: @color-light-0;
    padding: 2rem;
    border-radius: 12px;
    overflow-y: auto;
    p {
      font-size: 1rem;
    }
  }
  .close {
    padding: .5rem;
    background: @color-action-irreversible;
    opacity: 1;
    border-radius: 0.2rem;
    top: 1rem;
    right: 3rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;    
    color: @color-light-0;
    svg {
      transition: transform 0.2s ease-in-out;
      width: 1.1rem;
      height: 1.1rem;
      font-size: 0.5rem;
    }

    &:hover {
      svg {
        transform: rotate(90deg);
      }
    }
    // width: 2rem;
    // height: 2rem;
  }

}
