.historical-totals {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  // font-size: 1rem;
  .current {
    .value {
      font-weight: bold;
    }
  }
  .delta {
    &:before {
      content: ' '
    }
    font-weight: bold;
    &.good {
      color: @color-status-good;
    }
    &.bad {
      color: @color-status-alert;
    }
  }

}