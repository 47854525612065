@import (less) "../../../../node_modules/react-virtualized/styles.css";

.ReactVirtualized__Table {
  box-shadow: none;
  width: 100% !important;

  .ReactVirtualized__Table__Grid {
    background: transparent;
    outline: 0;
  }

  .ReactVirtualized__Table__headerRow {
    color: @color-column-0 !important;
    text-transform: none;
    font-weight: 600;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 12px;
    .ReactVirtualized__Table__headerColumn {
      display: flex;
      justify-content: center;
      .ReactVirtualized__Table__headerTruncatedText {
        flex: 1;
      }
      // .DragHandle {
      //   cursor: col-resize;
      //   align-self: stretch;
      //   padding: 0 5px;
      //   position: relative;
      //   left: 5px;
      //   display: flex;
      //   justify-content: stretch;
      //   min-height: 1.5rem;
      //   &:after {
      //     content: '';
      //     display: block;
      //     width: 1px;
      //     background: @color-light-3;
      //   }
      // }
      .active-tab-address-column{
        
      }
    }

    .ReactVirtualized__Table__headerTruncatedText {
      padding: 0.25rem 0;
    }
  }
  .ReactVirtualized__Table__row {
    transition: background 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    box-shadow: inset 0 0 0 rgba(255, 255, 255, 0);
    border-bottom: 2px solid @color-border-0 !important;
    // font-weight: 700;
    text-align: center;
    &:last-child {
      border-bottom: 0px;
    }
    .location-label{
      text-align: initial;
    }
    // &.even {
    //   background: @color-stripe-0;
    // }
    // &.odd {
    //   background: @color-stripe-1;
    // }
  }

  .column-inner {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .light {
    font-weight: 400;
    font-style: italic;
  }

  .ReactVirtualized__Table__rowColumn {
    align-self: flex-start;

    .row-expanded-section {
      // padding: 0.25rem 0 0 1rem;
      // color: @color-light-1;
    }

    .explorer-list + .modal-manager {
      margin-top: 0.5rem;
    }
  }
}
