.add-user-modal {
  .add-user-modal-content {
    &.busy {
      &:after {
        content: '';
        background: @color-dark-1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.5;
      }
    }
  }
}