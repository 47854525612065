input[type='radio'] {
  width: 1rem;
  height: 1rem;
  margin: 0;
  display: initial;
}

.curb-radio {
  display: flex;
  align-items: center;
  & > input {
    order: 2;
  }
  & > label {
    order: 1;
    flex-grow: 1;
  }
}

.curb-radio-group {

    .options-wrapper {
      display: flex;
      overflow: hidden;
      border-radius: 10px;
      box-shadow: 0 0 3px rgba(0,0,0,0.5);
      .curb-radio,
      .curb-radio:first-child {
        display: flex;
        padding: 0;
        width: 0;
        flex: 1;
        flex-basis: 1;
        align-items: stretch;

        label {
          overflow: hidden;
          margin: 0;
          cursor: pointer;
          text-align: center;
          background: none;
          font-weight: bold;
          font-size: 1.1rem;
          color: @color-action-main;
          border: 1px solid @color-dark-1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: 0;
          .label-content {
            padding: 0.5rem 1rem;
            opacity: 0.5;
            background: @color-light-3;
            color: @color-light-0;
          }
        }

        & + .curb-radio {
          label {
            border-left: none;
          }
        }
      }
      input[type=radio] {
        visibility: hidden;
        width: 0;
        height: 0;        
        &:checked  {
          & + .label-content {
            background: @color-action-main;
            color: @color-white;
            opacity: 1;
          }
        }
      }
    }
}