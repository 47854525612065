@import "vars.less";
@import "typography.less";
@import "structure.less";
@import "header.less";
@import "vendor.less";

body {
  background-color: @color-light-0;
  color: @color-text-default;
  min-width: @ipad-portrait-width;
  // background-image: url('../img/page-bg.png');
  // background-size: 25px 25px;
  // background-repeat: repeat;
}

a,
a:visited,
a:hover,
a:active,
a:focus,
.link {
  color: inherit;
  text-decoration: none;
  &[disabled] {
    color: @color-light-3;
  }
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

.link {
  cursor: pointer;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
