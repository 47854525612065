@h1-size: 3.2rem;

html:not(.wf-active) {
  .app-inner,
  .application-loader-message {
    visibility: hidden;
  }
}

body {
  font-family: "Inter", "Lato", sans-serif;
  font-size: 14px;
}

h1 {
  font-size: @h1-size;
}

h1,
h2,
h3,
h4,
p,
li {
  &:first-child {
    margin-top: 0;
  }
}

.section-title {
  display: flex;
  justify-content: center;
  min-height: 2rem;
  margin-top: -3rem;
  margin-bottom: 1rem;
  align-items: center;
  text-align: center;
  h3 {
    margin: 0;
    background: @color-light-5;
    min-width: 300px;
    padding: 0.5rem;
    border-radius: 5px;
    box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.5);
  }
}
