@keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loading-spinner {
    text-indent: -9999em;
    &:after {
      content: '';
      border-radius: 50%;
      padding: 0;
      width: 50px;
      height: 50px;
      line-height: 0;
      box-sizing: border-box;
      display: block;
      border-top: 10px solid rgba(197, 197, 197, 0.4);
      border-right: 10px solid rgba(197, 197, 197, 0.4);
      border-bottom: 10px solid rgba(197, 197, 197, 0.4);
      border-left: 10px solid #c5c5c5;
      transform: translateZ(0);
      animation: load8 1.1s infinite linear;
    }
  
    &.inline {
      display: inline-block;
      &:after {
        width: 25px;
        height: 25px;
        border-top-width: 5px;
        border-left-width: 5px;
        border-right-width: 5px;
        border-bottom-width: 5px;
      }
    }
  
    &.center {
      position: fixed;
      vertical-align: middle;
      margin-top: -25px;
      margin-left: -25px;
      top: 50%;
      left: 50%;
    }
  
    &.absolute {
      position: absolute;
    }
  
    &.tiny {
      margin-top: -3px;
      margin-bottom: -3px;
      &:after {
        width: 16px;
        height: 16px;
        border-width: 3.5px;  
      }
    }
  }