.column-users {
  & > .column-inner {
    padding: 0 !important;
    & > .location-row-users {
      padding: 0.5rem 0;
    }
  }
}

.expanded .column-users .empty {
  margin-bottom: 0.5rem;
}

.location-row-users {
  position: relative;
  text-align: initial;
  .remove-user {
    background-color: @color-status-alert;
    color: @color-light-0 !important;
    position: absolute;
    right: 100%;
    border-radius: 50%;
    // &:hover {
    //   color: @color-light-0;
    //   background-color: @color-status-alert;
    // }
  }

  .user-list {
    &.expanded-list {
      .list-item {
        overflow: hidden;
        .email {
          margin-left: 0.2rem;
        }
        .user-row-label {
          display: flex;
          justify-content: flex-start;
          .user-label {
            margin-left: 0.0rem;
          }
        }
      }
    }
  }

  .multi-user + .modal-manager {
    .add-user {
      display: flex;
      transform: translateX(-1.5rem);
      padding: 2rem;
    }
  }
}

@media screen and (max-width: 1500px) {
  .location-row-users {
    .user-label {
      overflow-x: auto;
      overflow-y: hidden;
    }
    .email {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}
