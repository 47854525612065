@import "label.less";
@import "date-modified.less";
@import "users.less";
@import "installations.less";
@import "billing.less";
@import "fleet.less";

.explorer-status {
  padding: 1rem;
}

.ReactVirtualized__Table {
  .ReactVirtualized__Table__row {
    // make sure loading spinners don't blow out the row
    .loading-spinner:not(.tiny) {
      &:after {
        max-height: 20px;
        max-width: 20px;
      }
    }

    button,
    .button {
      .loading-spinner {
        max-height: initial;
        max-width: initial;
      }
    }

    &.expanded {
      box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.25);

      .column-label,
      .column-users {
        overflow: visible !important;
      }

      .column-inner {
        padding-bottom: 0rem;
      }

      .ReactVirtualized__Table__rowColumn {
        .explorer-list + .modal-manager {
          margin-top: 0.5rem;
        }
      }
    }
    &.row-highlight {
      background-color: #e7f3ff;
    }
  }
  .column-header-left-align-label {
    justify-content: flex-start !important;
  }
}
