.energy-projections-values {
  .energy-projections-values-form {
    .inset + .inset {
      margin-top: 4rem;
    }

    &.busy {
      input,
      button[type="submit"] {
        opacity: 0.5;
      }
      &:after {
        background: none;
        opacity: 0;
      }
    }

    .loading-spinner {
      margin-right: 1rem;
    }

    .form-row {
      display: flex;
      align-items: center;
      .curb-form-element {
        flex: 1;
        margin-bottom: 0 !important;
        input {
          width: 100%;
        }
      }
      label {
        width: 120px;
        text-align: right;
        flex-shrink: 0;
        padding-right: 1rem;
      }
      & + .form-row {
        margin-top: 1rem;
      }

      .month-label {
        text-align: center;
        text-transform: uppercase;
      }
    }
    
  }
}
