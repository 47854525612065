.ReactVirtualized__Table .ReactVirtualized__Table__rowColumn {
  .location-row-installations {
    display: flex;
    justify-content: center;
    .linked-icon {
      margin-left: 0.5rem;
    }
    .loading-spinner {
      margin-left: 0.75rem;
    }
    .row-expanded-section {
      padding-left: 1.5rem;
      padding-top: 0;
    }
    .installation-list {
      // margin: 2rem 0;
      .installation-item {
        // & + .installation-item {
        //   padding: 1rem 0 0;
        // }
        display: flex;
        flex-direction: column !important;
        .connectivity-indicator .label {
          display: none;
        }
        .connectivity-loader {
          width: 16px;
          height: 16px;
          margin-left: 0;
          margin-right: 0.5rem;
          margin-top: 0;
        }
        .installation-error {
          display: inline-block;
          margin-right: 0.5rem;
          .status-inline svg {
            width: 16px;
            height: 16px;
            margin-right: 0 !important;
          }
        }
      }
      .hub-status {
        min-height: 3vh;
      }
      .serial-number {
        font-weight: bold;
      }
    }
  }
}
