@color-blue-light:  #6464a3;
@color-blue:        #444488;
@color-blue-dark:   #07074e;

@color-green-light: #bbee66;
@color-green:       #a3d93d;

@color-white:       #ffffff;
@color-gray-light:  #f5f4f4;
@color-gray:        #c5c5c5;
@color-gray-dark:   #7d7d7d;
@color-red:         #ff2d56;
@color-orange:      #fa8d00;
@color-yellow:      #f5d000;

@font-sans-serif: 'Lato', Helvetica, sans-serif;

.button(@color: @color-blue-light, @font-color: @color-white) {
  display: block;
  height: 45px;
  line-height: 45px;
  padding: 0 30px;
  color: @font-color;
  background-color: @color;
  text-align: center;
  outline: 0;
  border: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  font-size: 1em;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  border-radius: 3px;
  margin: 10px auto;
  // max-width:80%;

  &:active {
    background-color: darken(@color, 5%);
  }
}

.placeholder(@rules) {

    &::-webkit-input-placeholder {
        @rules();
    }
    &:-moz-placeholder {
        @rules();
    }
    &::-moz-placeholder {
        @rules();
    }
    &:-ms-input-placeholder {
        @rules();
    }
    &.placeholder {
        @rules();
    }
}

.gray-gradient() {
  background: #f4f4f4;
  background: -moz-linear-gradient(top, #F7F7F7 0%, #efefef 46%, #cccccc 100%);
  background: -webkit-linear-gradient(top, #F7F7F7 0%,#efefef 46%,#cccccc 100%);
  background: linear-gradient(to bottom, #F7F7F7 0%,#efefef 46%,#cccccc 100%);
}

/* RESPONSIVE MIXINS */
// TODO make more of these for different responsive breakpoints
// and use them instead of ad-hoc media queries
@iphone-6-plus-width: 414px;
@iphone-6-width: 375px;
@iphone-4-width: 320px;
@ipad-portrait-width: 768px;
@dashboard-single-column-width: 896px;

.respond-to-max-width(@maxWidth; @rules){
  @media only screen and (max-width: @maxWidth) {
      @rules();
  };
}

.respond-to-min-width(@maxWidth; @rules){
  @media only screen and (min-width: @maxWidth) {
      @rules();
  };
}

.respond-to-max-height(@maxWidth; @rules){
  @media only screen and (max-height: @maxWidth) {
      @rules();
  };
}

.respond-to-min-height(@maxWidth; @rules){
  @media only screen and (min-height: @maxWidth) {
      @rules();
  };
}

.respond-to-tablet(@rules){
  .respond-to-max-width(@ipad-portrait-width, @rules);
}

.respond-to-mobile-large(@rules){
  .respond-to-max-width(@iphone-6-plus-width, @rules);
}

.respond-to-mobile-small(@rules){
  .respond-to-max-width(@iphone-4-width, @rules);
}

.respond-to-dashboard-single-column(@rules){
  .respond-to-max-width(@dashboard-single-column-width, @rules);
}

.respond-to-landscape(@rules){
  @media only screen and (min-aspect-ratio: ~"1/1") {
    @rules();
  }
}

.respond-to-portrait(@rules){
  @media only screen and (max-aspect-ratio: ~"1/1") {
    @rules();
  }
}
